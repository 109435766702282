export const routes = [
    {
        to : 'home',
        title : ' Home',
        icon : 'fa-home'
    },
    {
        to : 'DataMatakuliah',
        title : ' Mata Kuliah',
        icon : 'fa-books'
    },
]

export const routes_admin = [
    {
        to : 'home',
        title : ' Home',
        icon : 'fa-home'
    },
    {
        to : 'DataDosen',
        title : ' Data Dosen',
        icon : 'fa-user'
    },
   
]