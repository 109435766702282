import { DownloadOutlined, EditOutlined, NodeExpandOutlined, SwapOutlined } from '@ant-design/icons';
import { Form, Modal, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import _Api from '../../services/Api/_Api';
import { Cache } from '../../services/Cache';
import { _Button, _Input } from '../../services/Forms/Forms';
import { _Col, _Row } from '../../services/Forms/LayoutBootstrap';
import { _Toastr } from '../../services/Toastr/Notify/_Toastr';

function ExpandTopic(pr) {
    const [detail, setDetail] = useState([])
    const [selected, setSelected] = useState([])
    const [loading, setloading] = useState(true)
    const [edit, setedit] = useState(false)
    const [items, setitems] = useState([])
    const histori = useHistory();

    const loadData = () => {
        setloading(true)
        _Api.get(`topic/${pr.data.id_mk}`).then(res => {
            setDetail(res.data)
            setloading(false)

        })
    };
    const prosesTopik = (data) => {
        setloading(true)
        _Api.get(`subject-detail/${pr.data.id_mk}`).then(res => {
            setloading(false)
            // let cek = {...data, ...res.data}
            let enc = JSON.stringify({ ...data, ...res.data })
            Cache.set("prosestopic", enc)
            histori.push("/ProsesTopik")
        })



    }

    const editTopik = (val) => {
        setedit(true)
        setitems(val)
    }


    const columns = [
        {
            title: '', width: 200,
            render: (text, row) => (
                < _Row style={{ textAlign: "center" }}>
                    <_Button sm={5} onClick={() => prosesTopik(row)} label="Proses" icon={<NodeExpandOutlined />} />
                    <_Button color="orange" sm={5} onClick={() => editTopik(row)} label="" icon={<EditOutlined />} />
                </_Row>
            ),
        },
        {
            title: 'No', width: 50,
            render: (text, row, index) => (
                < div style={{ textAlign: "center" }}> {index + 1} </div>
            ),
        },
        { title: 'Topic / Materi', dataIndex: 'topic_name', width: 300 },
        { title: 'Deskripsi', dataIndex: 'deskripsi', width: 500 },
        { title: 'Video', dataIndex: 'video', width: 300 },



    ];

    useEffect(() => {
        loadData()
    }, [])

    const hapusTopic = () => {
        // console.log(selected)
        selected.selectedRowKeys.map(item => {
            _Api.delete("topic-detail/" + item).then(res => {
                loadData()
                _Toastr.success("Suksess ...")
                setSelected([])
            }).catch(err => {
                _Toastr.error("Gagall ...")
            })
        })
    }
    const simpanTopik = (val) => {




        // "matakuliah_id" : "2",
        // "topic_name" : "Pertemuan 2 mantul",
        // "deskripsi" : "pada pertemuan ini kita akan mambahas",
        // "video" : "http://youtober.cyx"

        var obj = {
            ...val,
            subject_id: items.subject
        }

        _Api.put("topic-detail/" + items.id, obj).then(res => {
            loadData()
            _Toastr.success("Suksess ...")
            setedit(false)
            setitems([])
        }).catch(err => {
            _Toastr.error("Gagall ...")
        })
 
    }


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelected({ ...selected, selectedRowKeys })
        }
    };

    return (
        <div style={{ paddingLeft: "40px", background: "rgb(113 189 251 / 31%)" }}>
            {edit &&
                <Modal footer={[]} title="Edit Topic" visible={edit}
                    width={1000}
                    // onOk={handleOk}
                    onCancel={() => setedit(false)}
                >
                    <Form layout="horizontal" onFinish={simpanTopik} initialValues={items} wrapperCol={{span :"12"}} labelCol={{span : "5"}}  >
                        <_Input label="Nama Topic" name="topic_name" required />
                        <_Input label="Deskripsi" name="deskripsi" required />
                        <_Input label="Url Video"  name="video" required />
                        <br />
                        <_Row>
                            <_Col />
                            <_Button sm={3} block label="Simpan " submit icon={<DownloadOutlined />} />
                            <_Button sm={3} block label="Tutup" color="red" onClick={() => setedit(false)} />
                        </_Row>
                    </Form>

                </Modal>
            }

            <Table
                columns={columns}
                rowSelection={rowSelection}
                rowKey="id"
                loading={loading}
                pagination={false}
                scroll={{ x: 100, y: 2000 }}
                dataSource={detail}
                summary={pageData => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell ></Table.Summary.Cell>
                            <Table.Summary.Cell ></Table.Summary.Cell>
                            <Table.Summary.Cell ></Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={2}>

                                {selected.selectedRowKeys && selected.selectedRowKeys.length > 0 &&
                                    <div style={{ display: "flex" }}>
                                        <Popconfirm title={`Batal Kirim barang .? ?`} okText="YA" cancelText="Tidak"
                                            onConfirm={hapusTopic}
                                        >
                                            <_Button block sm={3} color="grey" size="small" icon={<SwapOutlined />} title="Hapus Topic" />
                                        </Popconfirm>
                                        {/* <_Button block sm={3} color="red" size="small" title="Hapus" /> */}
                                    </div>
                                }
                            </Table.Summary.Cell>

                        </Table.Summary.Row>
                    )
                }}
            // className={tableCSS}

            />
        </div>
    )
}

export default ExpandTopic
