import React, { useState, useEffect } from 'react'
import { Modal, List, Avatar, Spin } from 'antd'
import { SolutionOutlined } from '@ant-design/icons'
// services
import _Api, {baseURL, STORAGE } from '../../../services/Api/_Api';


const ResultAssigement = (props) => {
    // props from parent component
    const { visible, setVisible, assigement } = props
    // set state
    const [loading, setLoad] = useState(false);
    const [results, setResults] = useState([]);
    // auto load
    useEffect(() => {
        loadData();
    }, [])

    // load data result assigement
    const loadData = () => {
        setLoad(true)
        _Api.get(`result-assigement/${assigement}/lists`).then(res => {
            console.log(res.data)
            setResults(res.data)
            setLoad(false)
        }).catch(err => {
            console.log(err)
            setLoad(false)

        })
    }


    return (
        <>
            <Modal
                title="Results Assigement Lists."
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
            >
                {loading ? 
                <p style={{textAlign : 'center'}}><Spin size='large' /></p>
                    :
                    <List
                        itemLayout="horizontal"
                        dataSource={results}
                        renderItem={item => (
                            <List.Item
                                actions={[<a href={`${STORAGE}${item.file}`} target='_blank' key="list-loadmore-more" style={{ color: 'red' }}><SolutionOutlined /> File Tugas</a>]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={<a href="#">{item.student.user.first_name}</a>}
                                    description={`Email : ${item.student.user.email}`}
                                />
                            </List.Item>
                        )}
                    />
                }

            </Modal>
        </>
    );
}

export default ResultAssigement