import "../assets/css/app.css";
import _Header from "../layouts/Header";
import { useEffect } from "react";
import _Nav from "../layouts/_Nav";
import _Footer from "./_Footer";

function _MainLayouts({children}) {
 
  return (
    <div className="wrapper">
      <_Header />
      <div className="main">
        <_Nav />
        <main className="content">{children}</main>
        <_Footer />
      </div>
    </div>
  );
}

export default _MainLayouts;
